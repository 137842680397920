import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227bMAx9z1cIAQY0QBXIadJmLvohw9AHWqYdrrJkSHIuHfrvg2UnsWMnW4H1KQ4lUjyH5JHmMqoeVCnY7wljG6R842O2EuX+ecJYSq5UcIhZpjAYQFGuOXksXMwkao+2NudQxmzd+JSQpqTzmAn22FgSY1O03EJKlYvZsrGayivSGDNtNNaGHaV+E7NIiG/13wL2vDWtRJuPNMrYmG3B3nEOWUYauce956WlAuyBhw2z58nHZN7iijdmizagk5V1tXtp6Jh4AvItt6bS6UXU4DUMF4VAbQCOW9TenRGYEiT5Q8zEfN11GskhxQwq5S9zaCKdPBe9qiwXLXNbtJkyu5htKE1RdxwegsOgbPUvT8mi9GR0XPNYFXpQ0LDNebAhL29Bu8zYIm4+FXj8cSdmpzVqgp32MTFfOSarhCRP8J3Q3s2Xq/v5IroX99GMCdeF9jMFDxykpy2+TL2tcPrKejDGE+A1Dd2aLMPmi+4JUHJbkxSNczbk9RO0/aqcp6zuN+1R++4sdKlpG+L/EbNsFv2hxJepNrYANX0N+M+9dz1WBspdC9b08q1gq7CUGe25o3e8GJjGXoTuUGGCWnYXLbthw+4kMKK2VQ4td6hQ+s4UjZQqDPl5AZWi0pELsrEhj9yVIIOW7CyUI2pyqi3pkF2ijHzrgHscH5wgbC2AK9p3k5C9GyFEjBCybAgZE7hEgXzjKzHrpeu2ea/vW609HnL8PxaQpNHcoTQ6BXvohn36GhZutcVVST8lOFThp69Bv/57g9+qZ+fia6/CgvTFrtNZ3z8r1DcUp3a6KnOnE+Gsqa06gVJMzKMHxxBc9woWXS6P8MbGsgCbk+YKM996/euL4QacUoHEocf4KyIxe+42kNZ5HQWke6EGvCVY1OFOGzwBBg+FHlstH0cKenDXwwR6/dIYeTS7+c5o1GvdG+4knL0lRwmpIMNnxkeV+SL3oX/4Vv03SvQ8+fgD6wWK0v0JAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = 'c1u3lp0';
export var disable = 'c1u3lp1';
export var settingButton = 'c1u3lpa';
export var showOnCardHover = 'c1u3lpb';
export var workspaceActiveStatus = 'c1u3lp7';
export var workspaceInfo = 'c1u3lp4';
export var workspaceInfoSlide = 'c1u3lp3';
export var workspaceInfoSlider = 'c1u3lp2';
export var workspaceInfoTooltip = 'c1u3lp8';
export var workspaceName = 'c1u3lp5';
export var workspaceStatus = 'c1u3lp6';
export var workspaceTitleContainer = 'c1u3lp9';